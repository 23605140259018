import {FC} from "react";

type RadioButtonGroup = {
    name: string,
    label?: string,
    onChange: any,
    options: string[],
    type?: any
}

const RadioButtonGroup: FC<RadioButtonGroup> = ({type={mobile: "row", pc: "row"}, name, label= "", onChange, options}) => {
    return (
        <div>
            { label &&
                <p className="mb-[1rem]">{label}</p>
            }
            <div className={`flex gap-[.5rem] flex-${type.mobile} md:flex-${type.pc} flex-wrap`}>
                {options.map(option => (
                    <div key={option} className={`${type.mobile === "row" ? "w-[calc(50%-.25rem)]" : "w-full"} md:${type === "row" ? "w-[calc(50%-.25rem)]" : "w-full"} flex items-center ps-3 border border-gray-200 rounded-lg`}>
                        <input
                            id={`${label}-${option}`}
                            type="radio"
                            value={option}
                            name={name}
                            onChange={(e: any) => onChange(e.target.value)}
                            className="w-5 h-5 bg-gray-200 border-gray-200"
                        />
                        <label
                            htmlFor={`${label}-${option}`}
                            className="w-full py-3 ms-2 text-base font-medium text-gray-900"
                        >
                            {option}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RadioButtonGroup;