import PhoneInput, {isPossiblePhoneNumber} from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../context/AppContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";

const Phone = () => {
    const navigate = useNavigate();
    const {formData, onChangeFormData, setIsTargetUser, setIsTargetQueries, setNotification} = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [phone, setPhone] = useState("");
    const [formattedPhone, setFormattedPhone] = useState("+7"); // Стейт для отображения "+7" в поле ввода

    useEffect(() => {
        const phoneNumberInput = document.getElementById('phone-input') as any;

        phoneNumberInput?.addEventListener('keydown', function(event: any) {
            // Проверяем, нажата ли клавиша Backspace и поле пустое
            if (event.key === 'Backspace' && phoneNumberInput.value === '+7') {
                // Предотвращаем удаление "+7" и делаем поле только для чтения
                event.preventDefault();
                phoneNumberInput.setAttribute('readonly', 'readonly');
            } else {
                // В противном случае удаляем атрибут "readonly", чтобы пользователь мог вводить
                phoneNumberInput.removeAttribute('readonly');
            }
        });
    }, [])

    const onChange = (value: string) => {
        if (value) {
            if (value.startsWith("+7")) {
                setPhone(value); // Устанавливаем введенный номер в состояние
                setFormattedPhone(value); // Устанавливаем введенный номер в форматированное состояние
            } else {
                // Если введенный номер не начинается с "+7", добавляем "+7" и сохраняем в состояние
                setPhone("+7" + value.substring(2));
                setFormattedPhone("+7" + value.substring(2));
            }
        }
    }

    const onSubmit = async () => {
        if (formattedPhone && isPossiblePhoneNumber(formattedPhone)) {
            try {
                const res = await axios.post("/questionnaire/check-phone/", {
                    phone_number: formattedPhone
                }, {
                    headers: {
                        Token: "11XtESRX53uO7ixbWkMVh58byTBgVX1RXT97FlpAdMh0OZukHEQJOV3pPvYtj7qL9i7QaNqKz8I4QekiV4rHm7cKFCoqkBsBXczGk2xOGMplcaiQfYiRWxLPCQRffbzI"
                    }
                })
                if (!res.data.is_valid) {
                    localStorage.setItem("is_target_user", "false");
                    localStorage.setItem("stage", "no_target");
                    setIsTargetUser(false);
                    setIsTargetQueries(false);
                    return null;
                }
            } catch (e: any) {
                localStorage.setItem("is_target_user", "false");
                localStorage.setItem("stage", "no_target");
                setIsTargetUser(false);
                setIsTargetQueries(false);
                return null;
            }

            if (formattedPhone && isPossiblePhoneNumber(formattedPhone)) {
                localStorage.setItem("stage", "1");
                localStorage.setItem("formData", JSON.stringify({...formData, phone: formattedPhone}));
                onChangeFormData("phone", formattedPhone);
                navigate(`/questions/1?${searchParams}`);
            }
        } else {
            setNotification({
                open: true,
                type: "warning",
                message: "Пожалуйста, проверьте правильность введенного номера телефона"
            })
        }
    }
    return (
        <div className="form-fields">
            <h1>Пожалуйста введите номер для получения вознагражения*</h1>
            <p>
                * Вознаграждение будет зачислено на ваш баланс в течение 3х дней после прохождения опроса. <br/>
                Вознаграждение начисляется <span>в случае полного прохождения опроса.</span>
            </p>
            <p className="font-bold">
                Распространяется только на казахстанские номера.
            </p>
            <PhoneInput
                id="phone-input"
                defaultCountry="KZ"
                placeholder="+7 (701) 111 01 01"
                value={
                    formattedPhone.startsWith("+7")
                        ? formattedPhone
                        : "+7" + formattedPhone
                }
                onChange={(value) => {
                    onChange(value as string);
                }}
            />
            <button
                onClick={onSubmit}
                className="btn-next"
            >
                Далее
            </button>
        </div>
    )
}

export default Phone;