import {useNavigate, useSearchParams} from "react-router-dom";
import Logo from "../assets/images/logo.png";
import {useContext, useEffect} from "react";
import axios from "axios";
import {AppContext} from "../context/AppContext";

const WelcomePage = () => {
    const { setIsTargetQueries } = useContext(AppContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const gender = searchParams.get("gender");
    const age = searchParams.get("age");
    const city = searchParams.get("city");

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.post("/questionnaire/check-targeting/", {gender, age, city},
                    {
                        headers: {
                            Token: "11XtESRX53uO7ixbWkMVh58byTBgVX1RXT97FlpAdMh0OZukHEQJOV3pPvYtj7qL9i7QaNqKz8I4QekiV4rHm7cKFCoqkBsBXczGk2xOGMplcaiQfYiRWxLPCQRffbzI"
                        }
                    });
                if (!res.data.is_valid) {
                    localStorage.setItem("is_target_user", "false");
                    localStorage.setItem("stage", "no_target");
                    setIsTargetQueries(false);
                }
            } catch (err) {
                localStorage.setItem("is_target_user", "false");
                localStorage.setItem("stage", "no_target");
                setIsTargetQueries(false);
            }
        })()
    }, [])

    const goToPersonalData = () => {
        localStorage.setItem("stage", "personal");
        navigate(`/personal?${searchParams}`);
    }

    return (
        <div className="flex gap-[1rem] flex-col h-full">
            <div className="flex justify-between items-center">
                <p className="text-xl font-bold">
                    Здравствуйте! <br/>
                    Спасибо за проявленный интерес к опросу.
                </p>
                <img src={Logo} className="w-[64px] h-[64px]" alt="logo" />
            </div>
            <p>
                Компания TDS Media проводит опрос по предпочтениям в медиа среди населения Казахстана.
            </p>
            <p>Опрос займет не более 10 минут.</p>
            <p>Мы гарантируем конфиденциальность всех полученных от Вас данных.</p>
            <div>
                <p>Опрос состоит из двух блоков:</p>
                <ol className="list-decimal ml-[2rem]">
                    <li>
                        <strong>Скрининг</strong> - 3 вопроса, которые позволят нам определить ваш профиль и подходит ли данный опрос Вам для прохождения.
                    </li>
                    <li>
                        <strong>Основной опрос</strong> - данные вопросы задаются, если Вы подходите по профилю. В случае полного прохождения опроса - Вы получаете вознаграждение на баланс мобильного телефона.
                    </li>
                </ol>
            </div>
            <p>
                Пожалуйста, нажмите <span className="text-blue">Далее</span>, чтобы начать отвечать на вопросы.
            </p>
            <button
                onClick={goToPersonalData}
                className="btn-next"
            >
                Далее
            </button>
        </div>
    )
}

export default WelcomePage;