import {Navigate, useNavigate, useParams, useSearchParams} from "react-router-dom";
import RadioButtonGroup from "../ui/RadioButtonGroup";
import {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../context/AppContext";
import MultipleCheckBox from "../ui/MultipleCheckBox";
import {formFields} from "../services/formFields";
import {negatives} from "../services/constants";

const Input = ({name, value, field}: any) => {
    const {onChangeFormData} = useContext(AppContext);
    switch (field.type) {
        case "radio":
            return (
                <RadioButtonGroup name={name} type={field.listType} label={field.label} onChange={(value: string) => onChangeFormData(name, value)} options={field.options} />
            )
        case "checkbox":
            return (
                <MultipleCheckBox value={value || []} name={name} type={field.listType} label={field.label} onChange={(value: string) => onChangeFormData(name, value)} options={field.options} />
            )
        default:
            return (
                <RadioButtonGroup name={name} type={field.listType} label={field.label} onChange={(value: string) => onChangeFormData(name, value)} options={field.options} />
            )
    }
}

const QuestionsForm = ({configs}: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const {formData, setNotification} = useContext(AppContext);
    const {id = 1} = useParams();
    const title = configs.getStepLabel();
    const [validation, setValidation] = useState<{isValid: boolean, type: string, notificated?: boolean}>({isValid: true, type: "warning", notificated: false});

    const onSubmit = () => {
        let newValidation = {...validation};
        const configKeys = Object.keys(configs.fields);
        for (let i = 0; i < configKeys.length; i++) {
            const key = configKeys[i];
            const value = formData[key];
            if (value) {
                if (configs.fields[key].type === "checkbox") {
                    if (value.length === 0) {
                        newValidation = {isValid: false, type: "warning"};
                    } else if (value.length === 1 && !negatives.includes(value[0])) {
                        if (validation.notificated) {
                            newValidation = {isValid: true, type: "info", notificated: false};
                        } else {
                            newValidation = {isValid: false, type: "info", notificated: true};
                        }
                    } else {
                        newValidation = {isValid: true, type: "info", notificated: false};
                    }
                } else {
                    newValidation.isValid = true;
                }
            } else {
                newValidation = {isValid: false, type: "warning"};
                break;
            }
        }

        console.log("newValidation", newValidation);
        if (newValidation.isValid) {
            if (Number(id) + 1 <= Object.keys(formFields).length) {
                localStorage.setItem("stage", String(Number(id) + 1));
                localStorage.setItem("formData", JSON.stringify(formData));
                if (id === "1") {
                    const keys1 = Object.keys(formFields[1].fields).filter(key => formData[key] === "Нет").map(key => key.split("daily_")[1]);
                    formFields[2].fields =
                        Object.fromEntries(
                            Object.entries(formFields[2].fields)
                                .filter(item => keys1.includes(item[0].split("monthly_")[1])));
                }
                navigate(`/questions/${Number(id) + 1}?${searchParams}`);
            } else {
                localStorage.setItem("formData", JSON.stringify(formData));
                localStorage.setItem("stage", "final");
                navigate(`/final?${searchParams}`);
            }
            newValidation.isValid = true;
        } else {
            setNotification({
                open: true,
                type: newValidation.type,
                message: configs.errorMessage || ""
            })
        }
        setValidation(newValidation);
    }

    return (
        <div className="form-fields">
            {title &&
                <h1>{title}</h1>
            }
            { Object.keys(configs.fields).map((key: any) => (
                <Input value={formData[key]} key={key} name={key} field={configs.fields[key]} />
            ))}
            <button
                onClick={onSubmit}
                className="btn-next"
            >
                Далее
            </button>
        </div>
    )
}

const Questions = () => {
    const {formData} = useContext(AppContext);
    const {id=1} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const configs = formFields[id];

    if (configs.dependencies) {
        for (let i = 0; i < configs.dependencies.length; i++) {
            let isNext = false;
            const dependency = configs.dependencies[i];
            const key = dependency.key;
            const value = formData[key];
            const dependencyType = dependency.type;
            if (configs.fields[Object.keys(configs.fields)[0]].type === "checkbox") {
                if (dependencyType === "include") {
                    if (!value.includes(dependency.value)) {
                        console.log("1")
                        isNext = true;
                    }
                } else {
                    if (value.includes(dependency.value)) {
                        console.log("2")
                        isNext = true;
                    }
                }
            } else {
                if (dependencyType === "include") {
                    if (dependency.value !== value) {
                        console.log("3")

                        isNext = true;
                    }
                } else {
                    if (dependency.value === value && value !== undefined) {
                        console.log("4", dependency.value, value);

                        isNext = true;
                    }
                }
            }
            if (isNext) {
                localStorage.setItem("stage", String(Number(id) + 1));
                return <Navigate to={`/questions/${Number(id) + 1}?${searchParams}`} />
            }
        }
    }
    const formFieldsKeys = Object.keys(formFields).length;
    const progressBarWidth = ((Number(id)/formFieldsKeys) * 100);
    return (
        <>
            <div className="mb-[1.5rem] flex items-center gap-[1.5rem]">
                <p className="whitespace-nowrap text-[14px] text-gray-100">ВОПРОС <span className="text-black">{id}</span> ИЗ <span  className="text-black">{formFieldsKeys}</span></p>
                <div className="w-[405px] bg-gray rounded h-[6px]">
                    <div style={{width: `${progressBarWidth}%`}} className={`bg-blue rounded h-full`}></div>
                </div>
            </div>
            <QuestionsForm configs={configs} />
        </>
    )
}

export default Questions;