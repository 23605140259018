import App from "./app";
import "./index.css";
import ReactDOM from "react-dom/client";
import AppProvider from "./context/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <AppProvider>
        <App />
    </AppProvider>
)
