import YoutubeImage from "../assets/images/youtube.png";
import {messengers, musicNetworks, socNetworks} from "./constants";
import InstagramImage from "../assets/images/instagram.png";
import FacebookImage from "../assets/images/facebook.png";
import VkImage from "../assets/images/vk.png";
import TikTokImage from "../assets/images/tik-tok.png";
import KaspiImage from "../assets/images/kaspi.png";

export const formFields: any = {
    1: {
        getStepLabel: () => <h1>Смотрите ли Вы <span className="text-blue">ежедневно</span> видео на следующих ресурсах?
        </h1>,
        fields: {
            daily_online_apps: {
                label: "Онлайн видео в приложениях, на сайтах или через Smart TV",
                type: "radio",
                options: ["Да", "Нет"]
            },
            daily_our_tv_channels: {
                label: "Казахстанские телеканалы (Хабар, КТК, НТК, 31 канал и т.д.)",
                type: "radio",
                options: ["Да", "Нет"]
            },
            daily_foreign_tv_channels: {
                label: "Зарубежные телеканалы (Discovery, HBO, BBC и т.д.)",
                type: "radio",
                options: ["Да", "Нет"]
            },
            daily_russian_tv_channels: {
                label: "Российские телеканалы (Россия, ТНТ, МузТВ и т.д.)",
                type: "radio",
                options: ["Да", "Нет"]
            },
        },
        errorMessage: 'Пожалуйста, отметьте ответ "Да" или "Нет" для каждого вопроса'
    },
    2: {
        getStepLabel: () => <h1>Смотрите ли Вы <span>хотя бы раз в месяц</span> видео на следующих ресурсах?</h1>,
        fields: {
            monthly_online_apps: {
                label: "Онлайн видео в приложениях, на сайтах или через Smart TV",
                type: "radio",
                options: ["Да", "Нет"]
            },
            monthly_our_tv_channels: {
                label: "Казахстанские телеканалы (Хабар, КТК, НТК, 31 канал и т.д.)",
                type: "radio",
                options: ["Да", "Нет"]
            },
            monthly_foreign_tv_channels: {
                label: "Зарубежные телеканалы (Discovery, HBO, BBC и т.д.)",
                type: "radio",
                options: ["Да", "Нет"]
            },
            monthly_russian_tv_channels: {
                label: "Российские телеканалы (Россия, ТНТ, МузТВ и т.д.)",
                type: "radio",
                options: ["Да", "Нет"]
            },
        },
        errorMessage: 'Пожалуйста, отметьте ответ "Да" или "Нет" для каждого вопроса'
    },
    3: {
        getStepLabel: () => <h1>Что Вы смотрите <span>еженедельно больше всего по времени?</span></h1>,
        fields: {
            most_watch_time: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Онлайн видео в приложениях, на сайтах или через Smart TV",
                    "Казахстанские телеканалы (Хабар, КТК, НТК, 31 канал и т.д.)",
                    "Зарубежные телеканалы (Discovery, HBO, BBC и т.д.)",
                    "Российские телеканалы (Россия, ТНТ, МузТВ и т.д.)"
                ]
            }
        }
    },
    4: {
        getStepLabel: () => <h1>Где Вы чаще всего замечаете <span>баннерную рекламу</span>?</h1>,
        fields: {
            where_banner_ads: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "На различных сайтах и в мобильных приложениях",
                    "На улице: билборды, плакаты, щиты",
                    "На витринах магазинов",
                    "На остановках",
                    "На общественном транспорте или внутри него",
                    "В печатных изданиях: газеты/журналы"
                ]
            }
        }
    },
    5: {
        getStepLabel: () => <h1>Где Вы чаще всего замечаете <span>видео рекламу</span>?</h1>,
        fields: {
            where_video_ads: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "на YouTube, Instagram, Facebook, TikTok",
                    "на различных сайтах",
                    "на TV каналах",
                    "в других приложениях, кроме YouTube, Instagram, Facebook, TikTok",
                    "на уличных видео дисплеях",
                    "внутри магазинов, БЦ и ЦОН-ов"
                ]
            }
        }
    },
    6: {
        getStepLabel: () => <h1>Рекламу на каких площадках Вы чаще всего запоминаете? (Отметьте все подходящие
            варианты)</h1>,
        fields: {
            what_ad_placement: {
                type: "checkbox",
                listType: {
                    mobile: "col",
                    pc: "row"
                },
                options: [
                    "YouTube",
                    "Instagram",
                    "Tik-Tok",
                    "Вконтакте",
                    "Баннера на различных сайтах",
                    "Нелицензионные кинотеарты",
                    "Лицензионные кинотеатры",
                    "Аудиосервисы",
                    "Другое",
                    "Ничего из перечисленного"
                ]
            }
        }
    },
    7: {
        getStepLabel: () => <h1>Из каких источников Вы чаще всего получаете информацию? (Отметьте все подходящие варианты)</h1>,
        fields: {
            information_sources: {
                type: "checkbox",
                listType: "col",
                options: [
                    "Радио",
                    "Телевидение",
                    "Печатные СМИ",
                    "Сайты в интернете",
                    "Мессенджеры (например, Telegram, Whatsapp, Viber)",
                    "Соцсети (например, Instagram, Facebook, Tik-Tok)",
                    "YouTube",
                    "Друзья/ знакомые/ родственники",
                ]
            }
        }
    },
    8: {
        getStepLabel: () => <h1>На каком языке Вы понимаете рекламу? (Отметьте все подходящие варианты)</h1>,
        fields: {
            ad_language: {
                type: "checkbox",
                listType: "col",
                options: [
                    "Русский",
                    "Казахский",
                    "Английский",
                ]
            }
        }
    },
    9: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <img src={YoutubeImage} className="w-[212px] h-[48px]" alt="youtube"/>
                <h1 className="w-full">Смотрите ли Вы YouTube?</h1>
            </div>
        ),
        fields: {
            youtube: {
                type: "radio",
                options: [
                    "Да",
                    "Нет",
                ]
            }
        }
    },
    10: {
        getStepLabel: () => <h1>Как часто Вы смотрите YouTube?</h1>,
        fields: {
            youtube_frequency: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Ежедневно",
                    "Хотя бы раз в неделю",
                    "Хотя бы раз в месяц",
                    "Реже чем раз в месяц",
                ]
            }
        },
        dependencies: [{
            key: "youtube",
            value: "Да",
            type: "include"
        }]
    },
    11: {
        getStepLabel: () => (
            <div className="form-fields">
                <h1>Используете ли Вы <span>платную подписку YouTube Premium</span>?</h1>
                <p>Такая подписка позволяет пользоваться YouTube <span>без рекламы.</span></p>
            </div>
        ),
        fields: {
            youtube_premium: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Да",
                    "Нет",
                ]
            }
        },
        dependencies: [{
            key: "youtube",
            value: "Да",
            type: "include"
        }]
    },
    12: {
        getStepLabel: () => <h1>Планируете ли Вы использовать платную подписку YouTube Premium <span>в будущем</span>?
        </h1>,
        fields: {
            youtube_premium_future: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Да",
                    "Нет"
                ]
            }
        },
        dependencies: [{
            key: "youtube",
            value: "Да",
            type: "include"
        }]
    },
    13: {
        getStepLabel: () => <h1>Пользуетесь ли Вы следующими сервисами? (Отметьте все подходящие варианты)</h1>,
        fields: {
            services: {
                type: "checkbox",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Лицензионные кинотеатры (например, IVI, Кинопоиск, Megogo и т.п.)",
                    "Нелицензионные кинотеатры (например, seasonvar, rezka, hdrezka, kinogo, gidonline и т.п.) и/или скачиваю из интерента",
                    "Ничего из перечисленного",
                ]
            }
        }
    },
    14: {
        getStepLabel: () => <h1>Какие из следующих видеосервисов в настоящее время Вы используете? (Отметьте все
            подходящие варианты)</h1>,
        fields: {
            video_services: {
                type: "checkbox",
                listType: {
                    mobile: "col",
                    pc: "row"
                },
                options: [
                    "Кинопоиск",
                    "IVI",
                    "Megogo",
                    "Okko",
                    "Amediateka",
                    "Start",
                    "Netflix",
                    "Twitch",
                    "Другие",
                    "Ничего из перечисленного"
                ]
            }
        },
        dependencies: [{
            key: "services",
            value: "Лицензионные кинотеатры (например, IVI, Кинопоиск, Megogo и т.п.)",
            type: "include"
        }]
    },
    15: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <h1 className="w-full">Пользуететсь ли Вы социальными сетями?</h1>
                <div className="w-full">
                    <p className="text-blue mb-[1rem]">Например:</p>
                    <div className="w-full flex">
                        {socNetworks.map((soc, index: number) => (
                            <div key={index} className="w-full flex items-center flex-col gap-[.5rem]">
                                <img src={soc.img} className="w-[48px] h-[48px]" alt={soc.label}/>
                                <p>{soc.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ),
        fields: {
            what_social_networks: {
                type: "radio",
                options: [
                    "Да",
                    "Нет",
                ]
            }
        }
    },
    16: {
        getStepLabel: () => <h1>Сколько времени Вы проводите в социальных сетях <span>в среднем в день</span>?</h1>,
        fields: {
            social_networks_time: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Менее получаса",
                    "От получаса до 1 ч",
                    "1-2 ч",
                    "2-5 ч",
                    "5 часов и более"
                ]
            }
        },
        dependencies: [{
            key: "what_social_networks",
            value: "Да",
            type: "include"
        }]
    },
    17: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <img src={InstagramImage} className="w-[80px] h-[80px]" alt="youtube"/>
                <h1 className="w-full">Укажите как часто Вы используте <span>Instagram</span></h1>
            </div>
        ),
        fields: {
            instagram_frequency: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "row"
                },
                options: [
                    "Ежедневно",
                    "Несколько раз в неделю",
                    "Раз в неделю",
                    "Несколько раз в месяц",
                    "Раз в месяц",
                    "Не использую"
                ]
            }
        },
        dependencies: [{
            key: "what_social_networks",
            value: "Да",
            type: "include"
        }]
    },
    18: {
        getStepLabel: () => (
            <div className="form-fields">
                <h1 className="w-full">Сколько профилей у Вас в Instagram?</h1>
                <p className="text-blue">У некоторых людей несколько профилей - личный, для бизнеса, для ребенка и
                    т.п.</p>
                <p>Отметьте то, что подходит Вам.</p>
            </div>
        ),
        fields: {
            instagram_profile_count: {
                type: "radio",
                options: [
                    "Один",
                    "Два",
                    "Три",
                    "более 3х",
                ]
            }
        },
        dependencies: [{
            key: "instagram_frequency",
            value: "Не использую",
            type: "exclude"
        }, {
            key: "what_social_networks",
            value: "Да",
            type: "include"
        }]
    },
    19: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <img src={FacebookImage} className="w-[80px] h-[80px]" alt="youtube"/>
                <h1 className="w-full">Укажите как часто Вы используте <span>Facebook</span></h1>
            </div>
        ),
        fields: {
            facebook_frequency: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "row"
                },
                options: [
                    "Ежедневно",
                    "Несколько раз в неделю",
                    "Раз в неделю",
                    "Несколько раз в месяц",
                    "Раз в месяц",
                    "Не использую"
                ]
            }
        },
        dependencies: [{
            key: "what_social_networks",
            value: "Да",
            type: "include"
        }]
    },
    20: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <img src={VkImage} className="w-[80px] h-[80px]" alt="youtube"/>
                <h1 className="w-full">Укажите как часто Вы используте <span>Vkontakte</span></h1>
            </div>
        ),
        fields: {
            vk_frequency: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "row"
                },
                options: [
                    "Ежедневно",
                    "Несколько раз в неделю",
                    "Раз в неделю",
                    "Несколько раз в месяц",
                    "Раз в месяц",
                    "Не использую"
                ]
            }
        },
        dependencies: [{
            key: "what_social_networks",
            value: "Да",
            type: "include"
        }]
    },
    21: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <img src={TikTokImage} className="w-[80px] h-[80px]" alt="youtube"/>
                <h1 className="w-full">Укажите как часто Вы используте <span>Tik-Tok</span></h1>
            </div>
        ),
        fields: {
            tik_tok_frequency: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "row"
                },
                options: [
                    "Ежедневно",
                    "Несколько раз в неделю",
                    "Раз в неделю",
                    "Несколько раз в месяц",
                    "Раз в месяц",
                    "Не использую"
                ]
            }
        },
        dependencies: [{
            key: "what_social_networks",
            value: "Да",
            type: "include"
        }]
    },
    22: {
        getStepLabel: () => (
            <div className="form-fields">
                <h1 className="w-full">Сколько профилей у Вас в Tik-Tok?</h1>
                <p className="text-blue">У некоторых людей несколько профилей - личный, для бизнеса, для ребенка и
                    т.п.</p>
                <p>Отметьте то, что подходит Вам.</p>
            </div>
        ),
        fields: {
            tik_tok_profile_count: {
                type: "radio",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Один",
                    "Два",
                    "Три",
                    "более 3х",
                    "Нет профиля, пользуюсь без регистрации"
                ]
            }
        },
        dependencies: [{
            key: "what_social_networks",
            value: "Да",
            type: "include"
        }, {
            key: "tik_tok_frequency",
            value: "Не использую",
            type: "exclude"
        }]
    },
    23: {
        getStepLabel: () => <h1>Какие из представленных соцсетей Вы используете хотя бы 1 раз в месяц? (Отметьте все
            подходящие варианты)</h1>,
        fields: {
            what_social_networks_per_month: {
                type: "checkbox",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Twitter",
                    "Likee",
                    "Одноклассники",
                    "Snapchat",
                    "LinkedIn",
                    "Pinterest",
                    "Ничего из перечисленного",
                ]
            }
        },
        dependencies: [{
            key: "what_social_networks",
            value: "Да",
            type: "include"
        }]
    },
    24: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <h1 className="w-full">Пользуетесь ли Вы платными или бесплатными музыкальными сервисами?</h1>
                <div className="w-full">
                    <p className="mb-[1rem]">Например:</p>
                    <div className="w-full flex">
                        {musicNetworks.map((soc, index: number) => (
                            <div key={index} className="w-full flex items-center flex-col gap-[.5rem]">
                                <img src={soc.img} className="w-[48px] h-[48px]" alt={soc.label}/>
                                <p>{soc.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ),
        fields: {
            music_streaming: {
                type: "radio",
                options: [
                    "Да",
                    "Нет",
                ]
            }
        }
    },
    25: {
        getStepLabel: () => <h1>Какие из следующих аудиосервисов в настоящее время Вы используете? (Отметьте все
            подходящие варианты)</h1>,
        fields: {
            which_music_streaming: {
                type: "checkbox",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Яндекс Музыка",
                    "Spotify",
                    "Вконтакте Музыка",
                    "Apple Music",
                    "YouTube audio",
                    "Другие",
                ]
            }
        },
        dependencies: [{
            key: "music_streaming",
            value: "Да",
            type: "include"
        }]
    },
    26: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <h1 className="w-full">Пользуетесь ли Вы мессенджерами?</h1>
                <div className="w-full">
                    <p className="text-blue mb-[1rem]">Например:</p>
                    <div className="w-full flex">
                        {messengers.map((soc, index: number) => (
                            <div key={index} className="w-full flex items-center flex-col gap-[.5rem]">
                                <img src={soc.img} className="w-[48px] h-[48px]" alt={soc.label}/>
                                <p>{soc.label}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ),
        fields: {
            messengers: {
                type: "radio",
                options: [
                    "Да",
                    "Нет",
                ]
            }
        }
    },
    27: {
        getStepLabel: () => <h1>Какими мессенджерами Вы пользуетесь? (Отметьте все подходящие варианты)</h1>,
        fields: {
            which_messengers: {
                type: "checkbox",
                listType: {
                    mobile: "col",
                    pc: "col"
                },
                options: [
                    "Telegram",
                    "WhatsApp",
                    "FB Messenger",
                    "Viber",
                    "WeChat",
                    "Другое",
                ]
            }
        },
        dependencies: [{
            key: "messengers",
            value: "Да",
            type: "include"
        }]
    },
    28: {
        getStepLabel: () => (
            <div className="flex flex-col items-center gap-[1.5rem]">
                <img src={KaspiImage} className="w-[80px] h-[80px]" alt="youtube"/>
                <h1 className="w-full">Используете ли Вы приложение <span>Kaspi</span>?</h1>
            </div>
        ),
        fields: {
            kaspi: {
                type: "radio",
                options: [
                    "Да",
                    "Нет",
                ]
            }
        }
    }
}
