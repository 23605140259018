import CheckImage from "../assets/images/check-circle.png";
import RadioButtonGroup from "../ui/RadioButtonGroup";
import {useContext} from "react";
import {AppContext} from "../context/AppContext";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";

const FinalPage = () => {
    const {formData, onChangeFormData, setNotification} = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSubmit = async () => {
        if (typeof formData.is_up_balance !== "undefined") {
            try {
                const targeting = {...formData.targeting};
                delete formData.targeting;
                const data = {
                    answers: {...formData},
                    targeting,
                    phone_number: formData.phone
                }
                delete data.answers.phone;
                const res = await axios.post("/questionnaire/results/", data, {
                    headers: {
                        Token: "11XtESRX53uO7ixbWkMVh58byTBgVX1RXT97FlpAdMh0OZukHEQJOV3pPvYtj7qL9i7QaNqKz8I4QekiV4rHm7cKFCoqkBsBXczGk2xOGMplcaiQfYiRWxLPCQRffbzI"
                    }
                });
                localStorage.setItem("stage", "finished");
                navigate(`/finished?${searchParams}`);
            } catch (e) {
                console.log(e);
            }
        } else {
            setNotification({open: true, type: "warning"})
        }
    }

    return (
        <div className="form-fields">
            <div className="flex flex-col items-center gap-[1.5rem]">
                <img src={CheckImage} className="w-[80px] h-[80px]" alt="youtube"/>
                <h1 className="w-full">Благодарим Вас за ваше участие, ваши ответы очень важны для нас.</h1>
                <p className="w-full">Мы зачислим вознаграждение на ваш баланс в течение 3-х рабочих дней.</p>
                <p>Пожалуйста, отметьте <span>Да</span>, если Вы готовы получать оповещение о возможности участвовать в других опросах.</p>
            </div>
            <RadioButtonGroup
                name={"gender"}
                options={["Да", "Нет"]}
                onChange={(value: string) => {
                    localStorage.setItem("formData", JSON.stringify({
                        ...formData,
                        is_up_balance: value
                    }))
                    onChangeFormData("is_up_balance", value);
                }}
            />
            <button
                onClick={onSubmit}
                className="btn-next"
            >
                Отправить
            </button>
        </div>
    )
}

export default FinalPage;