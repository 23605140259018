import InstagramImage from "../assets/images/instagram.png";
import FacebookImage from "../assets/images/facebook.png";
import VkImage from "../assets/images/vk.png";
import TikTokImage from "../assets/images/tik-tok.png";
import YaMusicImage from "../assets/images/ya-music.png";
import SpotifyImage from "../assets/images/spotify.png";
import AppleImage from "../assets/images/apple-music.png";
import TgImage from "../assets/images/tg.png";
import WhatsappImage from "../assets/images/whatsapp.png";
import ViberImage from "../assets/images/viber.png";

export const cities = [
    "Астана",
    "Акмолинская область",
    "Актобе",
    "Актюбинская область",
    "Алматы",
    "Алматинская область",
    "Атырау",
    "Атырауская область",
    "Уральск",
    "Западно-Казахстанская область",
    "Тараз",
    "Жамбылская область",
    "Семей",
    "Талдыкорган",
    "Караганда",
    "Карагандинская область",
    "Костанай",
    "Костанайская область",
    "Кызылорда",
    "Кызылординская область",
    "Актау",
    "Мангистауская область",
    "Павлодар",
    "Павлодарская область",
    "Петропавловск",
    "Северо-Казахстанская область",
    "Шымкент",
    "Туркестанская область",
    "Усть-Камногорск",
    "Восточно-Казахстанская область"
]

export const socNetworks = [{
    label: "Instagram",
    img: InstagramImage
}, {
    label: "Facebook",
    img: FacebookImage
}, {
    label: "VK",
    img: VkImage
}, {
    label: "TikTok",
    img: TikTokImage
}]

export const musicNetworks = [{
    label: "Яндекс Музыка",
    img: YaMusicImage
}, {
    label: "Spotify",
    img: SpotifyImage
}, {
    label: "Apple Music",
    img: AppleImage
}]

export const messengers = [{
    label: "Telegram",
    img: TgImage
}, {
    label: "WhatsApp",
    img: WhatsappImage
}, {
    label: "Viber",
    img: ViberImage
}]

export const negatives = ["Ничего из перечисленного", "Не использую", "Нет профиля, пользуюсь без регистрации"]
