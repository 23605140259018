import {Navigate, Outlet, useLocation, useSearchParams} from "react-router-dom";
import ThankYou from "../components/ThankYou";
import {useContext, useEffect} from "react";
import {AppContext} from "../context/AppContext";
import Notification from "../components/Notification";

const isNumeric = (string: string) => /^[+-]?\d+(\.\d+)?$/.test(string)

const MainLayout = () => {
    const { pathname } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const {isTargetUser, isTargetQueries, setIsTargetQueries, setFormData} = useContext(AppContext);

    const gender = searchParams.get("gender");
    const age = searchParams.get("age");
    const city = searchParams.get("city");
    const clear = searchParams.get("clear");
    const stage = localStorage.getItem("stage") || "welcome";

    useEffect(() => {
        if (isTargetQueries || gender && age && city) {
            setIsTargetQueries(true);
            localStorage.setItem("is_target_queries", "true");
        } else {
            localStorage.setItem("is_target_queries", "false");
            localStorage.setItem("stage", "no_target");
        }
    }, [])

    if (clear) {
        setFormData({});
        localStorage.removeItem("is_target_queries");
        localStorage.removeItem("formData");
        localStorage.removeItem("is_target_user");
        localStorage.removeItem("stage");
        searchParams.delete("clear");
        return <Navigate to={`/?${searchParams}`} />
    }
    console.log("stage path", stage, pathname);
    if (stage === "welcome") {
        if (pathname !== "/") {
            return <Navigate to={`/?${searchParams}`} />
        }
    } else if (stage === "personal") {
        if (pathname !== "/personal") {
            return <Navigate to={`/personal?${searchParams}`} />
        }
    } else if (stage === "phone") {
        if (pathname !== "/phone") {
            return <Navigate to={`/phone?${searchParams}`} />
        }
    } else if (stage === "final") {
        if (pathname !== "/final") {
            return <Navigate to={`/final?${searchParams}`} />
        }
    } else if (stage === "finished" && pathname !== "/finished") {
        return <Navigate to={`/finished?${searchParams}`} />
    } else if (stage === "no_target" && pathname !== "/no-target") {
        return <Navigate to={`/no-target?${searchParams}`} />;
    } else {
        if (pathname !== `/questions/${stage}` && stage !== "finished" && isNumeric(stage)) {
            return <Navigate to={`/questions/${stage}?${searchParams}`} />
        }
    }
    // console.log("pathname", pathname, isTargetUser, isTargetQueries);
    // if (isTargetQueries && (isTargetUser || isTargetUser === "no_answered_yet") && pathname !== "/no-target") {
    //     return <Navigate to={`/no-target?${searchParams}`} />;
    // }

    return (
        <div className="w-full h-full md:h-full flex justify-center items-center bg-white">
            <div className="w-full h-full md:h-auto md:w-[652px] md:rounded-lg bg-white md:shadow-form p-[1.5rem] md:p-[40px]">
                <Outlet />
            </div>
            <Notification />
        </div>
    )
}

export default MainLayout;