import {useContext, useEffect} from "react";
import {AppContext} from "../context/AppContext";
import WarningIcon from "../assets/icons/warning.svg";
import InfoIcon from "../assets/icons/info.svg";

const Notification = () => {
    const {notification, setNotification} = useContext(AppContext);

    useEffect(() => {
        if (notification.open) {
            setTimeout(() => {
                setNotification({
                    open: false,
                    message: ""
                })
            }, 4500);
        }
    }, [notification.open])

    if (!notification.open) {
        return null;
    }
    const content = () => {
        switch (notification.type) {
            case "warning":
                return (
                    <>
                        <img src={WarningIcon} className="mt-[6px]"/>
                        <p className="text-white">
                            {notification.message || "Пожалуйста, Выберите один из вариантов"}
                        </p>
                    </>
                )
            case "info":
                return (
                    <>
                        <img src={InfoIcon} className="mt-[6px]"/>
                        <p className="text-white">
                            {notification.message || "Вы Выбрали только один вариант ответа, хотя могли Выбрать несколько - если все верно нажмите кнопку - Далее"}
                        </p>
                    </>
                )
            default:
                return <></>
        }
    }

    return (
        <div className="fixed top-[48px] z-10 w-full flex justify-center">
            <div style={{backgroundColor: notification.type === "warning" ? "rgba(239, 108, 0, 1)" : "rgba(2, 136, 209, 1)"}}
                 className={`w-[320px] rounded-lg px-[1rem] py-[6px] flex items-start gap-[.75rem]`}
            >
                {content()}
            </div>
        </div>
    )
}

export default Notification;