import {useContext, useState} from "react";
import {AppContext} from "../context/AppContext";
import SelectField from "../ui/SelectField";
import RadioButtonGroup from "../ui/RadioButtonGroup";
import {useNavigate, useSearchParams} from "react-router-dom";
import {cities} from "../services/constants";
import {postUsersData} from "../services/fetchers";

const Personal = () => {
    const navigate = useNavigate();
    const {formData, onChangeFormData, setIsTargetQueries, setIsTargetUser, setNotification} = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const onSubmit = async () => {
        if (!(formData.gender && formData.age && formData.city)) {
            setNotification({open: true, type: "warning"})
            return;
        }
        const gender = searchParams.get("gender");
        const age = searchParams.get("age");
        const city = searchParams.get("city");
        const targeting = {gender, age, city};
        if (gender === formData.gender && age?.trim() === formData.age.split("+")[0] && city === formData.city) {
            setIsTargetUser(true);
            localStorage.setItem("is_target_user", "true");
            localStorage.setItem("stage", "phone");
            localStorage.setItem("formData", JSON.stringify(formData));
            onChangeFormData("targeting", targeting as Record<string, any>);
            navigate(`/phone?${searchParams}`);
        } else {
            localStorage.setItem("is_target_user", "false");
            localStorage.setItem("stage", "no_target");
            setIsTargetUser(false);

            const data = {
                answers: {...formData},
                targeting
            }
            await postUsersData(data);

            navigate(`/no-target?${searchParams}`);
        }
    }

    return (
        <div className="form-fields">
            <h1>Ваши данные</h1>
            <RadioButtonGroup
                name={"gender"}
                options={["Мужской", "Женский"]}
                label="Укажите ваш пол"
                onChange={(value: string) => onChangeFormData("gender", value)}
            />
            <RadioButtonGroup
                name={"age"}
                options={["Младше 18", "18 - 24", "25 - 34", "35 - 44", "45 - 54", "55+"]}
                label="Укажите ваш возраст"
                onChange={(value: string) => onChangeFormData("age", value)}
            />
            <SelectField
                label="Выберите Ваш город из списка или область, если живете не в городе"
                options={cities}
                name="city"
                onChange={(value: string) => onChangeFormData("city", value)}
            />
            <button
                onClick={onSubmit}
                className="btn-next"
            >
                Далее
            </button>
        </div>
    )
}

export default Personal;