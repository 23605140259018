import axios from "axios";

export const postUsersData = async (data: any) => {
    try {
        const res = await axios.post("/questionnaire/results/", data, {
            headers: {
                Token: "11XtESRX53uO7ixbWkMVh58byTBgVX1RXT97FlpAdMh0OZukHEQJOV3pPvYtj7qL9i7QaNqKz8I4QekiV4rHm7cKFCoqkBsBXczGk2xOGMplcaiQfYiRWxLPCQRffbzI"
            }
        });
    } catch (err) {
        console.error("Error", err);
    }
}