import {FC} from "react";

type SelectFieldProps = {
    label: string,
    name: string,
    options: string[],
    onChange: (value: string) => void,
}

const SelectField: FC<SelectFieldProps> = ({label="", name="", options=[], onChange}) => {
    return (
        <div>
            <label htmlFor="countries" className="mb-4 block text-base font-medium">
                {label}
            </label>
            <select
                id={name}
                name={name}
                onChange={e => onChange(e.target.value)}
                className="h-[48px] outline outline-1 outline-gray-300 border-r-[14px] border-transparent border font-medium text-gray-900 text-base rounded-lg
                focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
                <option selected value="">Выберите из списка</option>
                { options.map((option: string) => (
                    <option value={option}>{option}</option>
                ))}
            </select>
        </div>
    )
}

export default SelectField;