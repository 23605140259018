import {FC} from "react";
import {negatives} from "../services/constants";

type MultipleCheckBoxType = {
    value?: string[],
    name: string,
    label?: string,
    onChange: any,
    options: string[],
    type?: Record<string, any>
}

const MultipleCheckBox: FC<MultipleCheckBoxType> = ({value=[], type= {mobile: "row", pc: "row"}, name, label= "", onChange, options}) => {
    const onChangeCheckBox = (e: any) => {
        const newValue = e.target.value;
        if (value.includes(newValue)) {
            onChange(value.filter(value => value !== newValue));
        } else {
            onChange([...value, newValue]);
        }
    }

    const checkIsDisabled = (option: string) => {
        if (negatives.includes(option)) {
            if (value.length === 0) {
                return false;
            } else if (value.length === 1 && negatives.includes(value[0])) {
                return false;
            } else {
                return true;
            }
        } else {
            if (value.length === 1 && negatives.includes(value[0])) {
                return true;
            }
            return false;
        }
    }

    return (
        <div>
            { label &&
                <p className="mb-[1rem]">{label}</p>
            }
            <div className={`flex gap-[.5rem] flex-col flex-${type.mobile} md:flex-${type.pc} flex-wrap`}>
                {options.map(option => (
                    <div key={option} className={`${type.mobile === "row" ? "w-[calc(50%-.25rem)]" : "w-full"} md:${type.pc === "row" ? "w-[calc(50%-.25rem)]" : "w-full"} flex items-center ps-3 border border-gray-200 rounded-lg`}>
                        <input
                            disabled={checkIsDisabled(option)}
                            id={option}
                            type="checkbox"
                            value={option}
                            name={name}
                            onChange={onChangeCheckBox}
                            className="w-5 h-5 bg-gray-200 border-gray-200"
                        />
                        <label
                            htmlFor={option}
                            className="w-full py-3 ms-2 text-base font-medium text-gray-900"
                        >
                            {option}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MultipleCheckBox;