const ThankYou = () => {
    return (
        <>
            <h1 className="mb-[1.5rem]">Благодарим Вас за ответы.</h1>
            <p>
                К сожалению, мы уже собрали достаточное количество ответов по данному профилю. Благодарим за проявленный интерес и потраченное время.
            </p>
        </>
    )
}

export default ThankYou;