import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import WelcomePage from "../pages/Welcome";
import Personal from "../pages/Personal";
import Phone from "../pages/Phone";
import Questions from "../pages/Questions";
import FinalPage from "../pages/FinalPage";
import Finished from "../pages/Finished";
import ThankYou from "../components/ThankYou";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route index element={<WelcomePage />}/>
                    <Route path="/personal" element={<Personal />}/>
                    <Route path="/phone" element={<Phone />}/>
                    <Route path="/questions/:id" element={<Questions />} />
                    <Route path="/final" element={<FinalPage />}/>
                    <Route path="/finished" element={<Finished />}/>
                    <Route path="/no-target" element={<ThankYou />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
