import {createContext, ReactNode, useState} from "react";
import axios from "axios";

type AppContextType = {
    appState: string;
    setAppState: (value: string) => void;
    stage: string;
    setStage: (value: string) => void;
    formData: Record<string, any>;
    setFormData: (value: Record<string, any>) => void;
    onChangeFormData: (key: string, value: string | Record<string, any>) => void;
    isTargetQueries: boolean;
    setIsTargetQueries: (value: boolean) => void;
    isTargetUser: boolean | string,
    setIsTargetUser: (value: boolean | string) => void;
    notification: Record<string, any>;
    setNotification: (value: Record<string, any>) => void;
};

type Props = {
    children: ReactNode;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

axios.defaults.baseURL = "https://services.tds.media/api";

const AppProvider = ({ children }: Props) => {
    const [isTargetQueries, setIsTargetQueries] = useState(JSON.parse(localStorage.getItem("is_target_queries") || "false"));
    const [isTargetUser, setIsTargetUser] = useState<boolean | string>(
        localStorage.getItem("is_target_user")
            ? JSON.parse(localStorage.getItem("is_target_user") || "false")
            : "no_answered_yet");
    const [stage, setStage] = useState("start");
    const [appState, setAppState] = useState("");
    const [formData, setFormData] = useState(JSON.parse(localStorage.getItem("formData") || "{}"));
    const [notification, setNotification] = useState({});

    const onChangeFormData = (key: string, newValue: string | Record<string, any>) => {
        setFormData({
            ...formData,
            [key]: newValue
        })
    }

    return (
        <AppContext.Provider
            value={{
                appState,
                setAppState,
                stage, setStage,
                formData, setFormData,
                onChangeFormData,
                isTargetQueries, setIsTargetQueries,
                isTargetUser, setIsTargetUser,
                notification, setNotification
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
